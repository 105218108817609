import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 查询 我的申请
export const getProcessInfoPageList = (params) =>
  request({
    url: REST_SERVICE.myApplication.getProcessInfoPageList,
    method: 'get',
    params,
  });

// 删除申请
export const deleteProcessInfo = (data) =>
  request({
    url: REST_SERVICE.myApplication.deleteProcessInfo,
    method: 'post',
    data,
  });

// 撤回申请
export const retractApply = (data) =>
  request({
    url: REST_SERVICE.myApplication.retractApply,
    method: 'post',
    data,
  });

// 导出 我的申请
export const exportwdsqExcel = (params) =>
  request({
    url: REST_SERVICE.download.wdsqExcel,
    method: 'buffer',
    params,
  });
// 生成申请表
export const printNotSignApply = (data) =>
  request({
    url: REST_SERVICE.print.printNotSignApply,
    method: 'post',
    data,
  });
// 打印申请表
export const printApply = (data) =>
  request({
    url: REST_SERVICE.print.printApply,
    method: 'post',
    data,
  });

// 打印受理凭证
export const estatePrintApply = (data) =>
  request({
    url: REST_SERVICE.print.estatePrintApply,
    method: 'post',
    data,
  });

// 打印产权证明
export const printCQZM = (data) =>
  request({
    url: REST_SERVICE.print.printCQZM,
    method: 'post',
    data,
  });

// 打印电子证照
export const printDyzm = (params) =>
  request({
    url: REST_SERVICE.print.printDyzm,
    method: 'get',
    params,
  });

// 获取我的申请信息
export const getProcessInfo = (params) =>
  request({
    url: REST_SERVICE.myApplication.getProcessInfo,
    method: 'get',
    params,
  });

// 根据id获取地址信息
export const getAddressByUserId = (params) =>
  request({
    url: REST_SERVICE.myApplication.getAddressByUserId,
    method: 'get',
    params,
  });

// 通过上传id获取资料
export const getFileList = (params) =>
  request({
    url: REST_SERVICE.hall.getFileList,
    method: 'get',
    params,
  });

//通过办事大厅code获取办事大厅详细信息 bsdtDetail
export const bsdtDetail = (params) =>
  request({
    url: REST_SERVICE.uums.bsdtDetail,
    method: 'get',
    params,
  });

//通过业务号获取快递方式 findByYwhAndPostType
export const findByYwhAndPostType = (params) =>
  request({
    url: REST_SERVICE.myApplication.findByYwhAndPostType,
    method: 'get',
    params,
  });

//通过id获取权利人详情
export const getRightHolder = (params) =>
  request({
    url: REST_SERVICE.myApplication.getRightHolder,
    method: 'get',
    params,
  });

// 通过id获取义务人详情
export const getObligor = (params) =>
  request({
    url: REST_SERVICE.myApplication.getObligor,
    method: 'get',
    params,
  });

// 设置/切换地址
export const saveOrUpdateAddress = (data) =>
  request({
    url: REST_SERVICE.myApplication.saveOrUpdateAddress,
    method: 'post',
    data,
  });

// 删除地址
export const deleteAddress = (data) =>
  request({
    url: REST_SERVICE.myApplication.deleteAddress,
    method: 'post',
    data,
  });

// 获取评价系统跳转链接
export const getPageUrl = (data) =>
  request({
    url: REST_SERVICE.myApplication.getPageUrl,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });

// 评价状态维护
export const saveEvaluateStatus = (params) =>
  request({
    url: REST_SERVICE.myApplication.saveEvaluateStatus,
    method: 'post',
    params,
  });
