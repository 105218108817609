import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';
const applicantFinishService = {
  // 获取完成申请详情
  completeApply(params) {
    return request({
      url: REST_SERVICE.apply.completeApply,
      method: 'get',
      params,
    });
  },
  // 获取办事大厅数据
  getBsdtDetail(params) {
    return request({
      url: REST_SERVICE.uums.bsdtDetail,
      method: 'get',
      params,
    });
  },
  // 获取需携带材料
  getFileList(params) {
    return request({
      url: REST_SERVICE.hall.getFileList,
      method: 'get',
      params,
    });
  },
  // 获取申请二维码
  getSqsEwm(params) {
    return request({
      url: REST_SERVICE.apply.getSqsEwm,
      method: 'get',
      params,
    });
  },
  // 根据业务号获取审批记录
  droolsCheckGetByYwh(params) {
    return request({
      url: REST_SERVICE.apply.droolsCheckGetByYwh,
      method: 'get',
      params,
    });
  },
  // 开始智能审批
  completeApplyResult(params) {
    return request({
      url: REST_SERVICE.apply.completeApplyResult,
      method: 'get',
      params,
    });
  },

  getGroupByYwh(params) {
    //根据业务号获取审批记录的结果
    return request({
      url: REST_SERVICE.apply.getGroupByYwh,
      method: 'get',
      params,
    });
  },
  //改为授权状态
  fileUploadToQlxxStepByWwywh(data) {
    return request({
      url: REST_SERVICE.apply.fileUploadToQlxxStepByWwywh,
      method: 'post',
      data,
    });
  },
};

export default applicantFinishService;
