<template>
  <div>
    <div class="main box-shadow-default apply-wc">
      <div class="main-top flex flex-a_c flex-j_c">
        <div class="wc-icon">
          <i v-show="success" class="el-icon-circle-check"></i>
          <i v-show="!success" class="el-icon-circle-close"></i>
        </div>
        <div class="wc-text" v-show="!success">
          <span class="big-black">申请失败！</span>
        </div>
        <div class="wc-text" v-show="success">
          <span class="big-black">恭喜您申请成功！</span>
        </div>
      </div>
      <div class="wc-content">
        <div class="left-wc" v-show="success">
          <span class="small-black">
            您的申请编号：
            <span>{{ flowInfoIng.wwywh }}</span>
            <br />申请事项：
            <span>{{ name }}</span>
            <br />
            <p v-show="$hasPermission('IEBDC:SQ:WC:YSSX')">
              预审时限：
              <span>{{ yssx }}工作日</span>
              <br />
            </p>
            <p v-show="$hasPermission('IEBDC:SQ:WC:BLSX')">
              办理时限：
              <span>请在{{ blsx }}工作日内到办事大厅办理</span>
              <br />
            </p>
            <p>
              可打印文件：
              <span class="is-print mr10" v-if="$hasPermission('IEBDC:DYQX:DYSQB')" @click="printApply()"
                >打印申请表</span
              >
              <span class="is-print" v-if="$hasPermission('IEBDC:DYQX:DYSLPZ')" @click="estatePrintApply()"
                >打印受理凭证</span
              >
              <br />
            </p>
            <p v-show="$hasPermission('IEBDC:SQ:WC:XDCL')">
              必须携带材料为：
              <span>{{ fileReuired }}(材料详情请查看办事指南)</span>
              <br />
            </p>
            <p>温馨提示：</p>
            <p class="redPoint">1.我们将尽快进行资料预审核，请关注后续消息提醒。</p>
            <p class="redPoint">
              2.关于电子证照：您的业务办结后，可以在本网站“电子证照查询”功能中，查询、下载电子不动产权证书/证明。也可以使用“新疆政务服务手机APP”，在“个人中心——我的证照”
              中查询电子证照。如查询不到，可咨询属地不动产登记中心，询问是否生成相应的电子证照。
            </p>
          </span>
        </div>
        <div class="right-wx">
          <div class="wc-ewm" v-show="$hasPermission('IEBDC:GNXS:WXGZH')">
            <span>微信扫码在手机获取申请表</span>
            <br />
            <img :src="sqsEwmImg" />
          </div>
        </div>
      </div>
      <div class="main-footer text-center">
        <el-button type="primary" @click="applyAgain()">继续申请</el-button>
        <el-button type="primary" @click="$router.push('/home-login-out')">返回首页</el-button>
        <el-button
          type="primary"
          v-if="moveShow"
          @click="$router.push({ path: '/home/userCenter', query: { activeName: 'tax' } })"
          >去缴税</el-button
        >
        <!--        <el-button type="primary" v-if="$hasPermission('IEBDC:DYQX:DYSQB')" @click="printApply()">打印申请表</el-button>-->
        <!--        <el-button type="primary" v-if="$hasPermission('IEBDC:DYQX:DYSLPZ')" @click="estatePrintApply()">打印受理凭证</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import applicantFinishService from '@/pages/iebdc/api/apply/applicant-finish';
import { pushTax } from '@/pages/iebdc/api/my-tax.js';
import { printApply, estatePrintApply } from '@/pages/iebdc/api/my-application.js';
import utils from '@/utils/utils';
import applicantUploadService from '@/pages/iebdc/api/apply/applicant-upload';
export default {
  name: 'finish',
  data() {
    return {
      name: '', //预审事项
      yssx: '', //预审时限
      blsx: '', //办理时限
      sqsEwmImg: '',
      fileReuired: '', //必须上传的材料
      success: true,
      moveShow: false,
    };
  },
  computed: {
    ...mapGetters(['selectedBsdtCode']),
    ...mapState('wysq-data', ['flowInfoIng']),
    ...mapState('taxStatus', ['taxStatus']),
  },
  created() {
    this.targetToStep('完成');
    this.name = this.flowInfoIng.subname;
    //获取申请完成数据
    this.completeApply();
    //获取办事大厅数据
    this.$hasPermission('IEBDC:SQ:WC:BLSX') && this.getBsdtDetail();
    //获取需携带材料
    this.$hasPermission('IEBDC:SQ:WC:XDCL') && this.getFileList(this.flowInfoIng.subcode);
    //获取申请二维码
    this.$hasPermission('IEBDC:GNXS:WXGZH') && this.getSqsEwm();
  },
  mounted() {},
  methods: {
    // 跳转到下一页面
    ...mapMutations('wysq-step', ['targetToStep']),
    //获取申请信息详情
    async completeApply() {
      const { success, data } = await applicantFinishService.completeApply({ qlxxId: this.flowInfoIng.id });
      if (success && data) {
        this.yssx = data.yssx;
        // 申请成功推送税务
        if (!this.flowInfoIng.wwywh) return;
        applicantUploadService.getProcessInfo({ wwywh: this.flowInfoIng.wwywh }).then((res) => {
          const { success, data, message } = res;
          if (success && data) {
            if (data.taxStatus === '2' && data.netFlowdefCode === 'N200104') {
              this.moveShow = true;
              this.pushToTax(this.flowInfoIng.id);
            }
          } else {
            // this.$message.error(message);
          }
        });
      } else {
        this.$message.error(data.message);
      }
    },
    //获取办事大厅详情
    async getBsdtDetail() {
      const { success, data } = await applicantFinishService.getBsdtDetail({ officeCode: this.selectedBsdtCode });
      if (success && data) {
        this.blsx = data.blsx;
      } else {
        this.$message.error(data.message);
      }
    },
    //获取携带材料
    async getFileList(code) {
      const { success, data } = await applicantFinishService.getFileList({ subCode: code });
      if (success && data) {
        this.fileData = data.filter(({ sfbx }) => sfbx);
        let tempArr = [];
        this.fileData.forEach((item) => {
          tempArr.push(item.clmc);
        });
        this.fileReuired = tempArr.join('，');
      } else {
        this.$message.error(data.message);
      }
    },
    //获取申请二维码
    async getSqsEwm() {
      const { success, data } = await applicantFinishService.getSqsEwm({ ywh: this.flowInfoIng.wwywh });
      if (success && data) {
        this.sqsEwmImg = data;
      } else {
        this.$message.error(data.message);
      }
    },
    // 推送税务
    pushToTax(qlxxId) {
      pushTax({ qlxxId: qlxxId }).then((res) => {
        if (res.success) {
          // this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    //打印申请表
    printApply() {
      printApply({ ywh: this.flowInfoIng.wwywh }).then((res) => {
        if (res.success) {
          window.open(res.data);
        } else {
          this.$message.error('打印失败');
        }
      });
    },
    estatePrintApply() {
      estatePrintApply({ ywh: this.flowInfoIng.wwywh }).then((res) => {
        if (res.success === true) {
          window.open(res.data);
        } else {
          this.$message.error('打印失败');
        }
      });
    },
    //继续申请
    applyAgain() {
      this.targetToStep('阅读须知');
      this.$router.push({
        name: 'apply',
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
@import 'src/styles/public';
.main {
  padding: 20px 120px;
  line-height: 40px;
  letter-spacing: 1px;
  background-color: #ffffff;
  p {
    margin: 0 0 10px;
    text-align: justify;
    text-justify: inter-ideograph;
    &.weight {
      font-weight: 700;
      color: rgba(85, 85, 85, 1);
    }
  }
}
.el-icon-error {
  color: red;
}
.main-top {
  line-height: 108px;
  div {
    display: inline-block;
  }
  span {
    font-size: 24px;
    color: #333333;
    font-weight: bolder;
  }
  .wc-icon {
    width: 100px;
    color: #72c901;
    i {
      font-size: 68px;
      float: right;
      margin: 20px;
      color: #2c8bff;
    }
  }
}

.apply-wc {
  flex: 1;
  margin-top: 20px;
  .wc-content {
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    background: #fafafa;
    .left-wc {
      width: 50%;
      padding-right: 20px;
      .is-print {
        color: #0d71f6;
        cursor: default;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .right-wx {
      width: 50%;
      position: relative;
      border-left: 1px dashed #e5e5e5;
      padding-right: 20px;
    }
    .wc-ewm {
      position: absolute;
      left: 50%;
      margin-left: -100px;
      width: 200px;
      height: 256px;
      top: 50%;
      margin-top: -128px;
      span {
        display: inline-block;
        position: absolute;
        bottom: 0;
        font-weight: 800;
        font-size: 14px;
        width: 200px;
        text-align: center;
      }
    }
  }
}
//智能审批相关样式
.intelligent {
  width: 100%;
  .title {
    height: 50px;
    line-height: 50px;
    background: #f3f3f3;
    padding: 0 150px;
    font-size: 15px;
  }
  .lineH {
    line-height: 40px;
  }
  .result {
    padding: 20px 150px;
    border-bottom: 1px solid #dddddd;
    .success {
      span {
        color: #72c901;
        font-size: 16px;
        i {
          margin: 0 10px 10px 0;
        }
      }
    }
    .fail {
      span {
        color: #f56c6c;
        font-size: 16px;
        i {
          margin: 0 10px 10px 0;
        }
      }
    }
    .radius {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: block;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    .redPoint {
      color: red;
      font-size: 14px;
      margin-left: 10px;
    }
    .SUCCESS {
      background-color: #0076ff;
    }
    .ERROR {
      background-color: #ffb100;
    }
    .WARNNING {
      background-color: #fe5658;
    }
  }
}
/deep/ .el-table th {
  height: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
/deep/ .el-table tr {
  td,
  th {
    text-align: center;
    position: relative;
  }
}
</style>
